import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import CogsBanner from '../components/CogsBanner';
import Login from '../components/Login';
import PaperContainer from '../components/PaperContainer';
import { useLoggedInUser } from '../utils/firebase/auth';
import { defaultPath, Path } from '../utils/routes';

export default function LoginPage() {
  const router = useRouter();
  const loggedInUser = useLoggedInUser();
  const initialEmail = useMemo(() => router.query.email as string | undefined, [router]);
  const [email, setEmail] = useState(initialEmail ?? '');
  const reauthenticate = useMemo(() => router.query.reauthenticate === 'true', [router]);
  const message = useMemo(() => router.query.message, [router]);

  useEffect(() => {
    loggedInUser &&
      loggedInUser.emailVerified &&
      !reauthenticate &&
      router.replace((router.query.continue as string | undefined) || defaultPath);
  }, [router, loggedInUser, reauthenticate]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '4em',
      }}
    >
      <CogsBanner />
      <PaperContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '2em',
        }}
      >
        {message ? (
          <Alert severity="info" sx={{ marginY: '1em' }}>
            {message}
          </Alert>
        ) : (
          <Typography
            style={{ marginBottom: '1em' }}
            align="center"
            variant="h6"
            color="textSecondary"
          >
            Sign in to your COGS account
          </Typography>
        )}
        <Login onEmailChange={setEmail} />
      </PaperContainer>
      <Typography style={{ marginTop: '2em' }} gutterBottom>
        Don&apos;t have an account?
      </Typography>
      <CreateAccountButton {...{ email }} />
    </div>
  );
}

function CreateAccountButton({ email }: { email?: string }) {
  const router = useRouter();
  const url = Path.Signup + (email ? '?' + new URLSearchParams({ email }).toString() : '');

  return (
    <Button
      style={{ margin: '1em' }}
      variant="contained"
      color="primary"
      href={url}
      onClick={(event) => {
        event.preventDefault();
        router.push(url);
      }}
    >
      Create a new account
    </Button>
  );
}
