import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useFirebaseAuthErrorMessage from '../hooks/useFirebaseAuthErrorMessage';
import { signIn, useLoggedInUser } from '../utils/firebase/auth';
import isValidEmail from '../utils/isValidEmail';
import { defaultPath, Path } from '../utils/routes';

export default function Login({ onEmailChange }: { onEmailChange: (email: string) => void }) {
  const router = useRouter();
  const currentUser = useLoggedInUser(); // User when re-authenticating
  const initialEmail = useMemo(
    () => currentUser?.email ?? (router.query.email as string | undefined),
    [currentUser?.email, router]
  );
  const [email, setEmail] = useState(initialEmail ?? '');
  const emailValid = useMemo(() => isValidEmail(email.toLowerCase()), [email]);
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState<Error>();
  const errorMessage = useFirebaseAuthErrorMessage(loginError);
  const [inProgress, setInProgress] = useState(false);
  const canSignIn = !inProgress && emailValid && password.length > 0;

  useEffect(() => {
    onEmailChange?.(email);
  }, [onEmailChange, email]);

  const handleSignIn = useCallback(() => {
    if (!canSignIn) return;
    (async () => {
      setLoginError(undefined);
      setInProgress(true);
      try {
        await signIn(email, password);
        router.replace((router.query.continue as string | undefined) || defaultPath);
      } catch (error) {
        console.info('Login error:', error);
        setLoginError(error as Error);
      } finally {
        setInProgress(false);
      }
    })();
  }, [canSignIn, email, password, router]);

  const signInOnEnter = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSignIn();
      }
    },
    [handleSignIn]
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {!currentUser && (
        <TextField
          error={Boolean(email) && !emailValid}
          margin="dense"
          type="email"
          label="Email"
          spellCheck={false}
          autoFocus={!initialEmail}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={signInOnEnter}
        />
      )}
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <TextField
          margin="dense"
          type="password"
          label="Password"
          autoFocus={!!initialEmail}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={signInOnEnter}
        />
        <Typography style={{ opacity: 0.5 }} align="center" variant="caption">
          <Link
            href={
              Path.ResetPassword + (email ? '?' + new URLSearchParams({ email }).toString() : '')
            }
          >
            Forgot password?
          </Link>
        </Typography>
      </Box>
      <Button
        style={{ margin: '2em' }}
        variant="contained"
        color="primary"
        disabled={!canSignIn}
        onClick={handleSignIn}
      >
        {currentUser ? 'Confirm' : 'Sign in'}
      </Button>
      {errorMessage && (
        <Typography align="center" color="error" variant="body2">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}
