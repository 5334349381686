export default function useFirebaseAuthErrorMessage(
  error: (Error & { code?: string }) | undefined
): string | undefined {
  if (!error) {
    return undefined;
  }

  switch (error.code) {
    case 'auth/expired-action-code':
      return 'This link has expired';
    case 'auth/invalid-action-code':
      return 'This link is invalid';
    case 'auth/invalid-email':
      return 'Invalid email address';
    case 'auth/user-disabled':
      return 'Account has been disabled';
    case 'auth/user-not-found':
      return 'Account not found';
    case 'auth/wrong-password':
      return 'Incorrect password';
    case 'auth/weak-password':
      return 'Password is not strong enough';
    default:
      return error.message;
  }
}
